import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Carousel } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import ShowMoreText from "react-show-more-text";
import { toast } from 'react-toastify'
import size from 'lodash/size'
import { MdKeyboardBackspace, MdOutlineShoppingCart } from 'react-icons/md';

import CartActions from '../../../redux/actions/cart'

import Markdown from "react-markdown";

const ProductMobileScreen = ({ product }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart.data)

  useEffect(() => {
    const element = document.querySelector(".product-info-mobile");
    element.addEventListener('scroll', setHeaderBackground)

    return () => {
      element.removeEventListener('scroll', setHeaderBackground);
    }
  }, [])

  const setHeaderBackground = () => {
    const element = document.querySelector(".product-content");

    const productContentPosition = element.getBoundingClientRect()
    const headerHeight = 80

    if ((productContentPosition.top - headerHeight) < 0) {
      document.querySelector(".headers").classList.add("bg-primary");
    } else {
      document.querySelector(".headers").classList.remove("bg-primary");
    }
  }

  const onProductAddToCart = () => {
    toast.success("Successfully Add to Cart")
    dispatch(CartActions.addToCart(product))
  }

  const cartCount = size(cart)

  return (
    <div className="product-detail-mobile">
      <div className="product-info-mobile">
        <div className='headers'>
          <MdKeyboardBackspace size={20} onClick={() => window.history.back()} />
          <Button onClick={() => navigate('/cart')}>
            <MdOutlineShoppingCart color='black' />
            {cartCount > 0 && <span className='cart-count'>{cartCount}</span>}
          </Button>
        </div>

        <Carousel
          indicators={product.images.length > 1}
          controls={false}
        >
          {product.images.map((image, index) => (
            <Carousel.Item key={index} className="image-carousel">
              <img src={image.url} alt={image.alt} />
            </Carousel.Item>
          ))}
        </Carousel>

        <div className="product-content">
          <div className="product-info">
            <h4 className="product-title">{product.name}</h4>
            {product.originalPrice && (
              <div className="product-original-price">
                ${parseFloat(product.originalPrice).toFixed(2)}
              </div>
            )}
            <div className="product-sale-price">${parseFloat(product.salePrice).toFixed(2)}</div>

            <hr className='mb-4 mt-2' />

            <h6>What's good for?</h6>
            <ShowMoreText
              lines={8}
              more="Show more"
              less="Show less"
              className="content-css"
              anchorClass="show-more-less-clickable"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              <Markdown>{product.benefits}</Markdown>
            </ShowMoreText>

            <h6 className="mt-5">How to use</h6>
            <Markdown>{product.howToUse}</Markdown>

            <h6 className="mt-5">Ingredient</h6>
            <Markdown>{product.ingredient}</Markdown>
          </div>
        </div>
      </div>

      <div className="product-actions">
        <Button className="btn-add-to-cart" onClick={onProductAddToCart}>Add to Cart</Button>
      </div>
    </div>
  )
}

export default ProductMobileScreen
