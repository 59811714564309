import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import useBreakpoint, { SIZE_XS, SIZE_SM } from '../../hooks/useBreakpoint'
import ProductActions from '../../redux/actions/products'

import ProductDesktopScreen from './components/ProductDesktopScreen'
import ProductMobileScreen from './components/ProductMobileScreen'

const Product = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const isSmallScreen = [SIZE_XS, SIZE_SM].includes(breakpoint)

  const products = useSelector(state => state.products.data)
  const product = products.find(product => product.id === parseInt(id))

  useEffect(() => {
    if (!product) {
      dispatch(ProductActions.getProducts())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  if (!product) {
    return null
  }

  return isSmallScreen
    ? <ProductMobileScreen product={product} />
    : <ProductDesktopScreen product={product} />
}

export default Product
