import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { AiFillCheckCircle, AiFillDollarCircle } from 'react-icons/ai';
import {  BsFillTelephoneFill } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { BiSolidMessageAltDetail } from 'react-icons/bi';

const SuccessAlert = ({ visible, onClose, order = {} }) => (
  <Modal show={visible} fullscreen="sm-down">
    <Modal.Body className="summary-modal">
      <AiFillCheckCircle size={60} />
      <h2>Order Success</h2>

      <Alert className='order-success-alert small-text' variant="success">
        <div className='text-center'>អរគុណសម្រាប់ការកុម៉្មង់របស់លោកអ្នក។</div>
        <div className='text-center'>ក្រុមការងារនឹងទំនាក់ទំនងតាមប្រអប់សារបន្តិចទៀតនេះ។</div>
      </Alert>

      <div className="order-summary">
        <div className="summary-row">
          <div className="summary-icon">
            <FaMapMarkerAlt />
          </div>
          <div className="summary-label">Delivery Location</div>
        </div>
        <div className="summary-row mb-2">
          <div className="summary-icon" />
          <div className="summary">{order.attributes?.Address}</div>
        </div>

        <div className="summary-row">
          <div className="summary-icon">
            <BsFillTelephoneFill />
          </div>
          <div className="summary-label">Contact Number</div>
        </div>
        <div className="summary-row mb-2">
          <div className="summary-icon" />
          <div className="summary">{order.attributes?.Phone}</div>
        </div>

        <div className="summary-row">
          <div className="summary-icon">
            <AiFillDollarCircle />
          </div>
          <div className="summary-label">Total Amount</div>
        </div>
        <div className="summary-row mb-2">
          <div className="summary-icon" />
          <div className="summary">$ {parseFloat(order.attributes?.Total).toFixed(2)}</div>
        </div>

        <div className="summary-row">
          <div className="summary-icon">
            <BiSolidMessageAltDetail />
          </div>
          <div className="summary-label">Remark for Driver</div>
        </div>
        <div className="summary-row">
          <div className="summary-icon" />
          <div className="summary">{order.attributes?.Note}</div>
        </div>
      </div>

      <div className='my-3 px-3 small-text'>
        *** សម្រាប់ពត៌មានបន្ថែម សូមទំនាក់ទំនងមកកាន់
        016/092/078/011/093 22 11 85
      </div>

      <Button className="btn-back-home mt-3" onClick={onClose}>Back to Home</Button>
    </Modal.Body>
  </Modal>
);

export default SuccessAlert;