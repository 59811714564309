import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import map from 'lodash/map'
import { Container, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'

import ProductActions from '../../redux/actions/products'
import CartActions from '../../redux/actions/cart'
import Product from './components/Product'
import NoProductFound from './components/NoProductFound'

const Home = () => {
  const dispatch = useDispatch()
  const products = useSelector(state => state.products.data)
  const search = useSelector(state => state.products.search)

  const displayProducts = products.filter(
    product => product.name.toLowerCase().includes(search.toLowerCase())
  )

  useEffect(() => {
    dispatch(ProductActions.getProducts())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAddToCart = product => {
    dispatch(CartActions.addToCart(product))
    toast.success("Successfully Add to Cart")
  }

  return (
    <Container className="home">
      <Row>
        <Col xs={12}>
          <div className="title">
            <h5>{search ? `Search Result for "${search}"` : 'All Products'}</h5>
          </div>

          {search && displayProducts.length === 0 && <NoProductFound />}

          <div className="products">
            <Row>
              {map(displayProducts, product => (
                <Col key={product.id} xs={6} sm={4} lg={3}>
                  <Product product={product} onAddToCart={() => onAddToCart(product)} />
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Home
