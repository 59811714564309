import axios from 'axios'
import { sumBy } from 'lodash'
import { CART_TYPES } from '../types'
import moment from 'moment'

const BASE_URL = "https://besoma.artbizdigital.com"

const addToCart = payload => ({
  type: CART_TYPES.ADD_TO_CART,
  payload,
})

const modifyCartQty = (id, qty) => ({
  type: CART_TYPES.MODIFY_CART_QTY,
  payload: { id, qty }
})

const removeFromCart = id => ({
  type: CART_TYPES.REMOVE_FROM_CART,
  payload: { id }
})

const clear = () => ({ type: CART_TYPES.CLEAR_CART })

const checkout = params => async dispatch => {
  try {
    const totalAmount = sumBy(params.products, product => product.salePrice * (product.qty || 1))

    const response = await axios.post(`${BASE_URL}/api/orders`, {
      data: {
        Date: moment().format('DD-MM-YYYY HH:mm'),
        Facebook: params.facebook,
        Phone: params.phone,
        Address: params.address,
        Note: params.note,
        Total: Number(parseFloat(totalAmount).toFixed(2)),
        Complete: false,
        Products: params.products.map(product => ({
          Name: `${product.qty || 1} x ${product.name}`,
          Price: product.salePrice
        }))
      }
    })

    dispatch(clear())
    params.onSuccess(response.data.data)
  } catch (e) {
    alert("There was an error while checking out. Please try again. មានបញ្ហាក្នុងពេលដែលកំពុងទិញទំនិញ សូមព្យាយាមម្តងទៀត")
  }
}

const actions = {
  clear,
  addToCart,
  modifyCartQty,
  removeFromCart,
  checkout,
}

export default actions
