export const PRODUCT_TYPES = {
  GET_PRODUCTS: 'GET_PRODUCT_PRODUCTS',
  SET_PRODUCTS: 'SET_PRODUCT_PRODUCTS',
  SET_PRODUCTS_LOADING: 'SET_PRODUCTS_LOADING',
  SET_PRODUCTS_PAGINATION_LOADING: 'SET_PRODUCTS_PAGINATION_LOADING',

  GET_PRODUCT: 'GET_PRODUCT',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_PRODUCT_LOADING: 'SET_PRODUCT_LOADING',

  SET_SEARCH_PRODUCT: 'SET_SEARCH_PRODUCT',
}

export const CART_TYPES = {
  ADD_TO_CART: 'ADD_TO_CART',
  MODIFY_CART_QTY: 'MODIFY_CART_QTY',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  CLEAR_CART: 'CLEAR_CART',

  CHECKING_OUT: 'CHECKING_OUT',
  CHECKED_OUT: 'CHECKED_OUT',
}
