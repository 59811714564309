import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import sumBy from 'lodash/sumBy'
import size from 'lodash/size'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { MdKeyboardBackspace } from 'react-icons/md'

import CartActions from '../../redux/actions/cart'
import useBreakpoint, { SIZE_XS, SIZE_SM } from '../../hooks/useBreakpoint'

import SuccessAlert from './components/SuccessAlert'

const Checkout = () => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart.data)
  const isSmallScreen = [SIZE_XS, SIZE_SM].includes(breakpoint)

  const [facebook, setFacebook] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [note, setNote] = useState('')
  const [page, setPage] = useState(null)

  const [order, setOrder] = useState({})

  const products = Object.values(cart)
  const totalAmount = sumBy(products, product => product.salePrice * (product.qty || 1))

  const onPhoneNumberKeyDown = e => {
    if (
      e.key === 'e' ||
      e.key === 'E' ||
      (!e.key.match(/\d/) && e.key !== 'Backspace')
    )
      e.preventDefault()
  }

  const onCheckoutSuccess = order => {
    setFacebook('')
    setPhone('')
    setAddress('')
    setNote('')

    setOrder(order)
  }

  const onCheckout = () => {
    if (products.length === 0) return alert("Please add product to cart first. សូមបញ្ចូលទំនិញទិញជាមុនសិន")
    if (!facebook || !phone) return alert("Please fill in your facebook name and phone number. សូមបំពេញឈ្មោះហ្វេសបុក និងលេខទូរស័ព្ទរបស់អ្នកជាមុនសិន")
    if (!address) return alert("Please fill in your address. សូមបំពេញអាសយដ្ឋានរបស់អ្នកជាមុនសិន")
    if (!page) return alert("Please select which page you used to bought. សូមជ្រើសរើស Page ដែលអ្នកធ្លាប់ប្រើប្រាស់ជាមុនសិន")

    const confirmation = window.confirm("Are you sure you want to place order? តើអ្នកពិតជាចង់បញ្ជាទិញមែនទេ?")

    if (!confirmation) return
    
    dispatch(CartActions.checkout({
      facebook,
      phone,
      address,
      products,
      note: note ? `Page: ${page}, Note: ${note}` : `Page: ${page}`,
      onSuccess: onCheckoutSuccess
    }))
  }

  return (
    <div>
      {isSmallScreen && (
        <div className='mobile-header'>
          <MdKeyboardBackspace size={20} onClick={() => window.history.back()} />
          <p>Check Out</p>
        </div>
      )}
      <Container>
        <Row>
          <Col xs={12}>
            {!isSmallScreen && (
              <div className="title">
                <h5>Please fill in your information</h5>
              </div>
            )}

            <div className="hide-scrollbar checkout-form">
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Facebook Name (ឈ្មោះហ្វេសបុក)</Form.Label>
                    <Form.Control
                      required
                      placeholder="Input your facebook name"
                      value={facebook}
                      onChange={e => setFacebook(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number (លេខទូរស័ព្ទ)</Form.Label>
                    <Form.Control
                      required
                      placeholder="Input your phone number"
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                      onKeyDown={onPhoneNumberKeyDown}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Address (អាសយដ្ឋាន)</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Input your address"
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Note (សំគាល់)</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Input note"
                      value={note}
                      onChange={e => setNote(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>សូមជ្រើសរើស Page អ្នកធ្លាប់កម៉្មុងពីមុន?</Form.Label>
                    <Form.Select value={page} onChange={e => setPage(e.target.value)}>
                      <option value="">Please select</option>
                      <option value="Channimol & Charms Co., LTD">Channimol & Charms Co., LTD</option>
                      <option value="Besoma Beauty">Besoma Beauty</option>
                      <option value="មិនធ្លាប់កម៉្មុងពីមុន">មិនធ្លាប់កម៉្មុងពីមុន</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="footer">
              <div className="total-amount">
                <p>Total Payment</p>
                <p>$ {parseFloat(totalAmount).toFixed(2)}</p>
              </div>
              <Button
                className="btn-checkout"
                onClick={onCheckout}
                disabled={products.length === 0}
              >
                Place Order
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <SuccessAlert
        visible={size(order) > 0}
        order={order}
        onClose={() => {
          setOrder(null)
          navigate('/')
        }}
      />
    </div>
  )
}

export default Checkout
