import React from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap'
import ShowMoreText from "react-show-more-text";
import { toast } from 'react-toastify'

import CartActions from '../../../redux/actions/cart'

import Markdown from "react-markdown";

const ProductDesktopScreen = ({ product }) => {
  const dispatch = useDispatch()

  const onProductAddToCart = () => {
    toast.success("Successfully Add to Cart")
    dispatch(CartActions.addToCart(product))
  }

  return (
    <Container className="product-detail">
      <Row>
        <Col xs={12}>
          <div className="title">
            <h5>Product Detail</h5>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4}>
          <div className="product-image">
            <Carousel
              indicators={product.images.length > 1}
              controls={product.images.length > 1}
            >
              {product.images.map((image, index) => (
                <Carousel.Item key={index} className="image-carousel">
                  <img src={image.url} alt={image.alt} />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Col>
        <Col md={6} lg={{ span: 6, offset: 1 }}>
          <div className="product-info">
            <h4 className="product-title">{product.name}</h4>
            {product.originalPrice && (
              <div className="product-original-price">
                ${parseFloat(product.originalPrice).toFixed(2)}
              </div>
            )}
            <div className="product-sale-price">${parseFloat(product.salePrice).toFixed(2)}</div>

            <h6>What's good for?</h6>
            <ShowMoreText
                lines={8}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                expanded={false}
                truncatedEndingComponent={"... "}
              >
              <Markdown>{product.benefits}</Markdown>
            </ShowMoreText>
            <div className="product-actions">
              <Button className="btn-add-to-cart" onClick={onProductAddToCart}>Add to Cart</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col xs={11}>
          <h6>How to use</h6>
          <Markdown>{product.howToUse}</Markdown>

          <hr className='mb-5 mt-5' />

          <h6>Ingredient</h6>
          <Markdown>{product.ingredient}</Markdown>
        </Col>
      </Row>
    </Container>
  )
}

export default ProductDesktopScreen
