import React from 'react'
import { MdShoppingBasket } from 'react-icons/md'

const NoProductFound = () => {
  return (
    <div className="no-product-found">
      <div className="image">
        <MdShoppingBasket size={80} color='white' />
      </div>

      <div className="text">
        <h5>No Product Found</h5>
      </div>
    </div>
  )
}

export default NoProductFound
