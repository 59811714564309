import Immutable from 'seamless-immutable'
import { PRODUCT_TYPES } from '../types'

const initialState = Immutable({
  data: [],
  search: '',
  loading: false,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_TYPES.SET_PRODUCTS_LOADING:
      return state.set('loading', action.payload)

    case PRODUCT_TYPES.SET_PRODUCTS:
      return state.set('data', action.payload)

    case PRODUCT_TYPES.SET_SEARCH_PRODUCT:
      return state.set('search', action.payload)

    default:
      return state
  }
}

export default reducer
