import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Routes, Route, Navigate } from "react-router-dom"

import 'react-toastify/dist/ReactToastify.css';

import Layout from './layout'
import Home from './containers/Home'
import Cart from './containers/Cart'
import Checkout from './containers/Checkout'
import Product from './containers/Product'

function App() {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/:id" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>

      <ToastContainer
        autoClose={1000}
        pauseOnHover={false}
        draggable={false}
      />
    </>
  )
}

export default App;
