import React from 'react'
import { MdAddShoppingCart } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const Product = ({ product, onAddToCart }) => {
  const navigate = useNavigate()

  return (
    <div className="product-card">
      <div className="image clickable" onClick={() => navigate(`/products/${product.id}`)}>
        <img src={product.thumbnail.url} alt={product.thumbnail.alt} />
      </div>
      <div className="content">
        <div className="product-title clickable" onClick={() => navigate(`/products/${product.id}`)}>
          <p>{product.name}</p>
        </div>
        <div className="price-and-action">
          <div className="price">
            {product.originalPrice && (
              <p className="original-price">
                ${parseFloat(product.originalPrice).toFixed(2)}
              </p>
            )}
            <p className="sale-price">
              ${parseFloat(product.salePrice).toFixed(2)}
            </p>
          </div>
          <div className='add-to-cart-icon'>
            <MdAddShoppingCart size={25} onClick={onAddToCart} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
