import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { MdKeyboardBackspace } from 'react-icons/md'

import ProductActions from '../../redux/actions/products'
import CartActions from '../../redux/actions/cart'

import useBreakpoint, { SIZE_XS, SIZE_SM } from '../../hooks/useBreakpoint'
import Product from './components/CartProduct'
import NoProductFound from '../Home/components/NoProductFound'

const Home = () => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart.data)
  const isSmallScreen = [SIZE_XS, SIZE_SM].includes(breakpoint)

  const products = Object.values(cart)
  const totalAmount = sumBy(products, product => product.salePrice * (product.qty || 1))

  useEffect(() => {
    dispatch(ProductActions.getProducts())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onQtyChange = (id, qty) => {
    if (qty === 0) {
      dispatch(CartActions.removeFromCart(id))  
    } else {
      dispatch(CartActions.modifyCartQty(id, qty))
    }
  }

  return (
    <div>
      {isSmallScreen && (
        <div className='mobile-header'>
          <MdKeyboardBackspace size={20} onClick={() => window.history.back()} />
          <p>Cart</p>
        </div>
      )}
      <Container>
        <Row>
          <Col xs={12}>
            {!isSmallScreen && (
              <div className="title">
                <h5>My Cart</h5>
              </div>
            )}

            <div className="hide-scrollbar cart-products">
              {products.length === 0 && <NoProductFound /> }

              <Row>
                {map(products, product => (
                  <Col key={product.id} xs={12}>
                    <Product product={product} onQtyChange={qty => onQtyChange(product.id, qty)} />
                  </Col>
                ))}
              </Row>
            </div>

            {products.length > 0 && (
              <div className="footer">
                <div className="total-amount">
                  <p>Total Payment</p>
                  <p>$ {parseFloat(totalAmount).toFixed(2)}</p>
                </div>
                <Button className="btn-checkout" onClick={() => navigate('/checkout')} >
                  Checkout
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home
