import React from 'react'
import { MdAddCircle, MdRemoveCircleOutline } from 'react-icons/md'

const Product = ({ product, onQtyChange }) => {
  return (
    <div className="cart-product">
      <div className="image">
        <img src={product.thumbnail.url} alt={product.name} />
      </div>
      <div className="content">
        <div className="product-info">
          <p className="product-title">{product.name}</p>
          <p className="price">${parseFloat(product.salePrice).toFixed(2)}</p>
        </div>
        <div className="actions">
          <MdRemoveCircleOutline size={20} onClick={() => onQtyChange((product.qty || 1) - 1)} />
          <p className="qty">{product.qty || 1}</p>
          <MdAddCircle size={20} onClick={() => onQtyChange((product.qty || 1) + 1)} />
        </div>
      </div>
    </div>
  )
}

export default Product
