import Immutable from 'seamless-immutable'
import { CART_TYPES } from '../types'

const initialState = Immutable({
  data: {},
  checkingOut: false,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_TYPES.ADD_TO_CART:
      return state.setIn(['data', action.payload.id], action.payload)

    case CART_TYPES.MODIFY_CART_QTY:
      return state.setIn(['data', action.payload.id, 'qty'], action.payload.qty)

    case CART_TYPES.REMOVE_FROM_CART:
      return state.set('data', state.data.without(action.payload.id))

      case CART_TYPES.CLEAR_CART:
        return state.set('data', {})

    default:
      return state
  }
}

export default reducer
