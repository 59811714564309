import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import size from 'lodash/size'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { MdOutlineShoppingCart } from 'react-icons/md'

import ProductActions from '../redux/actions/products'
import useBreakpoint, { SIZE_XS, SIZE_SM } from '../hooks/useBreakpoint'

const Layout = ({ children }) => {
  const breakpoint = useBreakpoint()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const search = useSelector(state => state.products.search)
  const cart = useSelector(state => state.cart.data)
  const location = useLocation()

  const isSmallScreen = [SIZE_XS, SIZE_SM].includes(breakpoint)
  const isHomePage = location.pathname === '/'
  const isProductPage = location.pathname.startsWith("/products/")
  const showHeader = isHomePage || !isSmallScreen

  const setSearch = value => dispatch(ProductActions.setSearch(value))

  const onSearchKeyUp = event => {
    if (!isProductPage || event.defaultPrevented) {
      return
    }

    const key = event.key || event.keyCode

    if (key === 'Enter') {
      navigate("/")
    }
  }

  const cartCount = size(cart)

  return (
    <div className="layout">
      <Container className='header-container'>
        <Row>
          <Col xs={12}>
            {showHeader && (
              <div className='header'>
                <h4 className="clickable" onClick={() => navigate('/')}>BESOMA EXPRESS ORDER</h4>
                {(isHomePage || isProductPage) && (
                  <div className='search-bar'>
                    <input
                      type='text'
                      placeholder='Search for products...'
                      onChange={e => setSearch(e.target.value)}
                      onKeyUp={onSearchKeyUp}
                      value={search}
                    />
                    <Button onClick={() => navigate('/cart')}>
                      <MdOutlineShoppingCart />
                      {cartCount > 0 && <span className='cart-count'>{cartCount}</span>}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {children}
    </div>
  )
}

export default Layout
